import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "./login-button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

export const NavBarButtons = () => {
  const { isAuthenticated, logout, user } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <div>
      {!isAuthenticated && <LoginButton />}

        {isAuthenticated && (
          <>
            <DropdownButton id="dropdown-item-button" title={<Image src={user.picture} alt="Profile" roundedCircle width={30} height={30} />}>
              <Dropdown.ItemText className="fw-semibold border-bottom pb-2">
                {user.nickname}
              </Dropdown.ItemText>
            <Dropdown.Item as={Link} to="/profile" className="small fw-semibold text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width='22' strokeWidth={1.5} stroke="currentColor" className="size-6 me-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
              Conta
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/assine" className="small fw-semibold text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width='22' strokeWidth={1.5} stroke="currentColor" className="size-6 me-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
              </svg>
              Planos
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/dadosmensais" className="small fw-semibold text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width='22' strokeWidth={1.5} stroke="currentColor" className="size-6 me-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
              </svg>
              Dados Mensais
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/dashboard" className="small fw-semibold text-muted">
              <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" viewBox="0 -960 960 960" width="22px" stroke="currentColor"  className='size-6 me-2'><path d="M280-280h80v-280h-80v280Zm160 0h80v-400h-80v400Zm160 0h80v-160h-80v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>
              Dashboard
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={handleLogout} className="small fw-semibold text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width='22' strokeWidth={1.5} stroke="currentColor" className="size-6 me-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
              </svg>
              Sair
            </Dropdown.Item>
          </DropdownButton>
        </>
      )}
    </div>
  );
};
