import React from "react";
import BootstrapTable from 'fad-react-bootstrap-table-next'
import { Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import MainAd from "./MainAd.js";
import coinlogo from "./logos/coinlogo.svg";

const CoinsTable = (props) => {
  var coins = props.data
  // var chart = props.chart

  const columns = [
    {
      dataField: 'Symbol',
      text: 'Par',
      hidden: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (rowA.Symbol === 'DGTABRL') return -1;
        if (rowB.Symbol === 'DGTABRL') return 1;
        return 0;
      }
    }, {
      dataField: 'SymbolName',
      text: 'Nome',
      sort: true,
      classes: "freeze text-center text-lg-start",
      headerClasses: "freeze text-center text-lg-start",
      formatter: (cell, row, rowIndex, extraData) => (<>
        <Link to={row.Symbol}>
          <svg className="tablelogo" role="img" aria-labelledby={row.SymbolName}>
            <title id={row.SymbolName}>{row.SymbolName}</title>
            <use href={coinlogo + "#" + (row.Symbol.slice(0, -3)).toLowerCase() + "-logo"}></use>
          </svg>
          <br className="d-lg-none" />
          {row.SymbolName} <span className="text-muted text-decoration-none">{row.Symbol.slice(0, -3)}</span>
        </Link>
        {
          row.SymbolName === "digitra.com token" ? <MainAd /> : <></>
        }
      </>
      )
    }, {
      dataField: 'Last',
      text: 'Preço',
      sort: true,
      classes: 'toupdate',
      formatter: (cell, row, rowIndex, extraData) => {
        return <span className="text-nowrap">{(row.Last > 0.1) ? row.Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + row.Last.toLocaleString('pt-BR', { style: 'decimal', maximumSignificantDigits: 5 }))}</span>
      }
    }, {
    //   dataField: '24h',
    //   text: '24h%',
    //   sort: true,
    //   formatter: (cell, row, rowIndex, extraData) => {
    //     return (
    //       <>{last.length !== 0 ? (
    //         (row.Last - last.find(i => i.Symbol === row.Symbol)['24h']) / row.Last >= 0
    //           ? <span className="text-success"> {((row.Last - last.find(i => i.Symbol === row.Symbol)['24h']) / last.find(i => i.Symbol === row.Symbol)['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
    //           : <span className="text-danger"> {((row.Last - last.find(i => i.Symbol === row.Symbol)['24h']) / last.find(i => i.Symbol === row.Symbol)['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
    //       ) : <> </>
    //       }
    //       </>
    //     );
    //   },
    //   sortValue: (cell, row, rowIndex, extraData) => ((row.Last - last.find(i => i.Symbol === row.Symbol)['24h']) / last.find(i => i.Symbol === row.Symbol)['24h'])
    // }, {
      // dataField: '7d',
      // text: '7d%',
      // sort: false,
      // hidden: (typeof chart !== "undefined") ? true : false ,
      // formatter: (cell, row, rowIndex, extraData) => {
      //   if(typeof chart[row.Symbol] !== "undefined"){
      //   return (
      //     <>
      //       {
      //         (row.Last - chart[row.Symbol][0].Last) / row.Last >= 0
      //           ? <span className="text-success"> {((row.Last - chart[row.Symbol][0].Last) /  chart[row.Symbol][0].Last).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
      //           : <span className="text-danger"> {((row.Last - chart[row.Symbol][0].Last) / chart[row.Symbol][0].Last).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
      //       }
      //     </>
      //   )}
      //   else {
      //     return(<> </>)
      //   }
      //   ;
      // },
      // sortValue: (cell, row, rowIndex, extraData) => ((row.Last - chart[row.Symbol][0].Last) / row.Last)
    }, {
      dataField: 'QuoteVol',
      text: 'Volume (24h)',
      sort: true,
      formatter: (cell, row, rowIndex, extraData) => {
        return <>{row.QuoteVolume.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br />
          <span className="text-muted small">{parseInt(row.Volume).toLocaleString('pt-BR', { style: 'decimal' }) + " " + row.Symbol.slice(0, -3)}</span></>
      },
      sortValue: (cell, row, rowIndex, extraData) => (row.QuoteVolume)
    },
    // {
    //   dataField: 'WeekChart',
    //   text: 'Gráfico 7D',
    //   sort: true,
    //   hidden: (typeof chart !== "undefined") ? true : false ,
    //   formatter: (cell, row, rowIndex, extraData) => {
    //     if(typeof chart[row.Symbol] !== "undefined"){
    //       return (
    //     <WeekChart rawchart={chart[row.Symbol]} symbol={row.Symbol} />)
    //     }
    //     else{return<></>}
    //     }
    // }
    ];
    const defaultSorted = [{
      dataField: 'Symbol',
      order: 'asc',
      sortFunc: (a, b) => {
        if (a === 'DGTABRL') return -1;
        if (b === 'DGTABRL') return 1;
        return 0;
      },
      sortPrecedence: 1
    }, {
      dataField: 'QuoteVol',
      order: 'desc',
      sortPrecedence: 2
    }];
    
    const rowClasses = (row) => {
      let classes = null;
      if (row.SymbolName === "digitra.com token") {
        classes = 'bg-sponsored';
      }
      return classes;
    };

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        hover
        striped
        bootstrap4
        keyField="Symbol"
        data={coins}
        columns={columns}
        defaultSorted={defaultSorted}
        loading={true}
        rowClasses={ rowClasses } 
        noDataIndication={<Spinner variant="primary" className="m-5"  animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner>}
      />
    </>
  )
}
export default CoinsTable