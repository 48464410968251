import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import Dashboard from "../components/restricted/Dashboard/Dashboard.js"
import { checkRoleClaim, getAdminResource } from "./services/message.service";
import { Container, Alert } from 'react-bootstrap';

export const DashboardPage = () => {
  const [permission, setPermission] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true;
    document.title = "Dashboard · MercadoCripto";

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getAdminResource(accessToken, 'profilestatus');

      if (!isMounted) {
        return;
      }

      if (!!data && data.message === "yes") {
        if (checkRoleClaim(accessToken, 'read:cryptodotcomdashboard')) {
          setPermission(true);
        } else {
          setPermission(false);
        }
      }
      else {
        setPermission(false)
      }
      if (error) {
        setPermission(false);
      }
    };
    getMessage();
    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  if (permission) {
    return (<Dashboard />)
  }
  else {
    return (
      <Container className="p-5 mt-2">
        <Alert variant="primary" className="bd-callout p-4">
          <Alert.Heading>Compre relatórios personalizados</Alert.Heading>
          <p>Entre em contato para fazer um upgrade do seu plano para ter acesso à essa página, com informações sobre o lucro das corretoras e os pares mais negociados no mês e mais informações.</p>
          <div>
            <a href='/assine' title="Saiba Mais" className="btn btn-lg btn-primary">Saiba mais</a>
          </div>
        </Alert>
      </Container>
    )
  }
};