import React, { useState, useEffect } from "react";
import { Container, Spinner } from 'react-bootstrap';
import ExchangesDonut from "../../../charts/ExchangesDonut";
import DashSymbolsTable from "./DashSymbolsTable";

const DailySymbols = (props) => {
  document.title = "Dashboard Plano Empresarial · MercadoCripto";
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [donutData,setDonutData] = useState(null);
  const [tableData,setTableData] = useState(null);
  const [totalVolume,setTotalVolume] = useState(null);

    useEffect(() => {
      let ignore = false;
      const fetchData = async () => {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL + "/v1/ticker/market-data")
          const json = await response.json();
          if (!ignore) {
            setTotalVolume(json.reduce((a, e) => a + e.QuoteVolume, 0))
            setDonutData(json.sort((a, b) => b.QuoteVolume - a.QuoteVolume))
            setTableData(json.sort((a, b) => b.QuoteVolume - a.QuoteVolume).slice(0, 5))
            setIsLoaded(true);
          }
        } catch (error) {
          setError(error);
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
      return () => {
        ignore = true;
      };
    }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else {
    return (
      <>
        <DashSymbolsTable data={tableData} totalVolume={totalVolume}/>
        <ExchangesDonut data={donutData} />
      </>
    )
  }
}

export default DailySymbols