import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const ExchangesDonut = ({ data }) => {

  const [chartData, setChartData] = useState({
    series: [],
    options: {}
  });

  useEffect(() => {
    let sortedData = [...data].sort((a, b) => b.QuoteVolume - a.QuoteVolume);

    if (sortedData.length > 6) {
      let others = {
        "Symbol": "Outros",
        "SymbolName": "Outros",
        "Last": 0,
        "Volume": 0,
        "QuoteVolume": 0
      };

      for (let i = 6; i < sortedData.length; i++) {
        others.QuoteVolume += sortedData[i].QuoteVolume;
        others.Volume += sortedData[i].Volume;
        others.Last = (others.Last * (i - 6) + sortedData[i].Last) / (i - 5);
      }

      sortedData = sortedData.slice(0, 6);
      sortedData.push(others);
    }

    const pieData = {
      labels: sortedData.map(item => item.Symbol),
      series: sortedData.map(item => item.QuoteVolume)
    };

    // Calculate total volume
    const totalVolume = pieData.series.reduce((acc, value) => acc + value, 0);

    setChartData({
      series: pieData.series,
      options: {
        chart: {
          type: 'donut',
        },
        labels: pieData.labels,
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#008080', '#C4BBAF'],
        tooltip: {
          y: {
            formatter: (value, { seriesIndex }) => {
              const percentage = ((value / totalVolume) * 100).toFixed(1); // Calculate percentage
              return `${value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} (${percentage}%)`; // Format tooltip
            }
          }
        },
        stroke: {
          width: 0.5,
          colors: '#f8f9fa'
        },
        plotOptions: {
          pie: {
            donut: {
              size: '50%',
            }
          }
        },
        dataLabels: {
          style: {
            colors: ['#0c0c0c']
          },
          dropShadow: {
            enabled: false,
          },
          formatter: (val, opts) => {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + '%'];
          }
        },
        legend: {
          show: false
        }
      }
    });


  }, [data]);

  return (
    <div className="text-center mx-auto w-100" style={{ height: "264" }} >
      <span className="h5 fw-semibold"> Volume por par </span>
      <Chart options={chartData.options} series={chartData.series} className='text-center mx-auto w-100 d-flex justify-content-center align-items-center' type="donut" height="264" width="264" />
    </div>
  );
};

export default ExchangesDonut;
