import { callExternalApi } from "./external-api.service";
import { jwtDecode } from 'jwt-decode'

const apiServerUrl = process.env.REACT_APP_API_URL;

export const getPublicResource = async () => {
  const config = {
    url: `${apiServerUrl}/v1/api/messages/public`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getProtectedResource = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/v1/api/authenticated`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getAdminResource = async (accessToken, endpoint) => {
  const config = {
    url: `${apiServerUrl}/v1/api/permission/${endpoint}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const checkRoleClaim = async (accessToken, roleToClaim) => {
  const userData = jwtDecode(accessToken);
  return userData.permissions.indexOf(roleToClaim) !== -1
};