import React, { useState } from "react";
import { Container, Row, Form, Col, Alert } from 'react-bootstrap';
import MonthDashboard from './MonthDashboard';
import DailyDashboard from './DailyDashboard';
import DailySymbols from './DailySymbols';
import fees from '../../../utils/fees.json';

const RelatoriosClosed = (props) => {
  document.title = "Dashboard Plano Empresarial · MercadoCripto";
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isExchFive, setIsExchFive] = useState(true);

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const onSwitchExchangeNumber = () => {
    setIsExchFive(!isExchFive);
  };

  return (
    <>
      <title>Dashboard Plano Empresarial · MercadoCripto</title>
      <meta name="description" content="Relatórios mensais do volume negociado por cada corretoras de criptomoedas no Brasil, separado por par, moeda e volume. Ranking de corretoras brasileiras de criptomoedas e dados históricos de todas as corretoras do Brasil." />
      <div className="py-4">
        <Container>
          <Row xs="auto mb-1">
            <Col xs={3} lg="auto"><h1 className="h2 font-weight-bolder"> <span className={!isSwitchOn ? 'text-dark' : 'text-muted'}>Últimas 24 horas</span></h1></Col>
            <Col xs={3} lg="auto" className="text-center my-auto pb-2"><Form.Check type="switch" id="anual-mensal" aria-label="anual-mensal" checked={isSwitchOn} onChange={onSwitchAction} className="custom-control-input" /></Col>
            <Col xs={3} lg="auto"><h1 className="h2 font-weight-bolder"><span className={!isSwitchOn ? 'text-muted' : 'text-dark'}>Mês corrente</span></h1></Col>
          </Row>
          <p className="lead fw-normal">O primeiro acesso do dia pode demorar até 10 minutos para ser processado uma vez solicitado. Favor aguardar e recarregar a página.<br />
            As taxas e lucros são calculados pela soma das taxas <em className="fw-semibold text-primary">Taker</em> e <em className="fw-semibold text-primary">Maker</em>.</p>

          <Row xs="auto">
            <Col xs={3} lg="auto"><h5 className="font-weight-bolder"> <span className={isExchFive ? 'text-dark' : 'text-muted'}>5 corretoras</span></h5></Col>
            <Col xs={3} lg="auto" className="text-center my-auto pb-2"><Form.Check type="switch" id="exch-five" aria-label="exch-five" checked={!isExchFive} onChange={onSwitchExchangeNumber} className="custom-control-input" /></Col>
            <Col xs={3} lg="auto"><h5 className="font-weight-bolder"><span className={isExchFive ? 'text-muted' : 'text-dark'}>Todas as corretoras</span></h5></Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <h3>Corretoras</h3>
            <p>Volume de todas as criptomoedas negociadas contra o BRL período.</p>
            {!isSwitchOn ?
              <DailyDashboard fees={fees} isExchFive={isExchFive} />
              :
              <MonthDashboard fees={fees} isExchFive={isExchFive} />
            }
          </Col>
          <Col>
            <h3>Criptomoedas</h3>
            <p>Volume de todas as corretoras no período.</p>
            {!isSwitchOn ?
              <DailySymbols />
              :
              <Alert variant="warning" className="bd-callout p-4 mt-4">
                <Alert.Heading>
                  <svg xmlns="http://www.w3.org/2000/svg" height="42" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 me-3">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                  </svg>
                  Em desenvolvimento</Alert.Heading>
                <p>A tabela e o gráfico de dados consolidados do mês corrente agregado por moedas ainda estão em desenvolvimento.</p>
              </Alert>
            }
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default RelatoriosClosed