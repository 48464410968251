import React, { useEffect } from "react";
import { Container, Alert } from 'react-bootstrap';

const Metodologia = () => {
    useEffect(() => {
        document.title = "Metodologia de cálculo do índice de preço das criptomoedas · MercadoCripto"
    }, []);
    return (
        <Container className="mt-3">
            <div className="mb-4">
                <h1>Sobre o MercadoCripto</h1>
                <p className="lead">
                    O portal mercadocripto foi criado com o objetivo de disponibilizar os dados de volume e preço de todas as corretoras nacionais de uma forma simples, objetiva e transparente.</p>
            </div>

            <div className="mb-4">
                <h3 className="pb-2">Metodologia de Cáculo do Índice</h3>
                <p>O rápido crescimento do número de corretoras de Bitcoin (BTC) e criptomoedas nacionais, aliado à grande variação de preço entre elas, motivou a criação de um índice de preço único para cada criptomoeda negociada em Real Brasileiro (BRL).</p>
                <p>O valor do índice de cada criptomoeda é calculado a partir do preço negociado em cada corretora, ponderado pelo volume negociado, conforme a fórmula abaixo:</p>
                <var className="p-4 align-middle"><span style={{ fontSize: "30px", fontFamily: "serif" }}>&sum; </span>(P * V<sub>i</sub> / V) </var> onde:
                <p><var>P</var> = Preço da criptomoeda na corretora.<br />
                    <var>V<sub>i</sub></var> = Volume negociado da criptomoeda nas últimas 24 negociado na corretora.<br />
                    <var>V</var> = Soma volume negociado da criptomoeda nas últimas 24h em todas as corretoras monitoradas.</p>
            </div>

            <Alert variant="info" className="bd-callout p-4 my-4">
                <Alert.Heading>
                    <svg xmlns="http://www.w3.org/2000/svg" height="42px" viewBox="0 -960 960 960" className="me-2" fill="currentColor"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                    Você tem uma corretora?</Alert.Heading>
                <p>Entre em contato para anunciar ou para listar sua corretora: <Alert.Link href='mailto:mercadocripto@livecoins.com.br'>mercadocripto@livecoins.com.br</Alert.Link></p>
            </Alert>
            
            <div className="mb-4">
                <h3 className="pb-2">Critérios para Listagem</h3>
               <p>Para que uma corretora seja listada no portal e adicionada ao índice, adotamos as seguintes diretrizes para admissibilidade:</p>
                <ol>
                    <li>A corretora disponibiliza uma API aberta e gratuita, de forma que os usuários sejam capazes de verificar o volume e o preço sem logar na plataforma.</li>
                    <li>A API pública deve conter um <span className="font-italic">endpoint</span> com um resumo de cada par negociado nas últimas 24h. O exemplo abaixo pode ser usado como guia:
                        <pre className="rounded p-4 mt-3 codeblock"><code>
                            <span className="text-white">&#123;</span><br />
                            "Symbol": <span className="string">"BTCBRL"</span><span className="text-white">,</span><br />
                            "Last": <span className="int">180590.52</span><span className="text-white">,</span><br />
                            "Volume": <span className="int">0.48826806</span><span className="text-white">,</span><br />
                            "Quote_Volume": <span className="int">90299.55</span><br />
                            <span className="text-white">&#125;</span>
                        </code></pre>
                    </li>
                    <li>Fácil verificação se os dados da API batem com os dados da plataforma.</li>
                    <li>Volume de negociação no par deve ser constantemente maior do que zero nas útlimas 24 horas.</li>
                </ol>
            </div>
        </Container>
    )
}

export default Metodologia