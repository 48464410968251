import React, { useState, useEffect } from "react";
import { Container, Spinner } from 'react-bootstrap';
import Treemap from "../../../charts/ExchangeTreeMap";
import DashExchangesTable from "./DashExchangesTable";

const DailyDashboard = (props) => {
  document.title = "Dashboard Plano Empresarial · MercadoCripto";
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tableData,setTableData] = useState(null);
  const [exchangeNumber, setExchangeNumber] = useState(5);
  const treeChartSeries = useData(exchangeNumber)

  useEffect(() => {
    setExchangeNumber(props.isExchFive ? 5 : 30);
  }, [props.isExchFive]);

  function useData(exchangeNumber) {
    const [data, setData] = useState(null);
    
    useEffect(() => {
      let ignore = false;
      const fetchData = async () => {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL + "/v1/exchanges/all"
          );
          const json = await response.json();
          if (!ignore) {
            setData(json);
            setTableData(json.sort((a, b) => b.VolumePercent - a.VolumePercent).slice(0, exchangeNumber));
            setIsLoaded(true);
          }
        } catch (error) {
          setError(error);
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
      return () => {
        ignore = true;
      };
    }, [exchangeNumber]); 

    return data;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else {
    return (
      <>
        <DashExchangesTable data={tableData} fees={props.fees}/>
        <Treemap data={treeChartSeries} />
      </>
    )
  }
}

export default DailyDashboard