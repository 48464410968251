import React, { useState, useEffect } from "react";
import { Container, Spinner, Row, Col, Breadcrumb, ListGroup } from 'react-bootstrap';
import { Link, useParams, useLocation } from "react-router-dom";
import ExchangeSummaryTable from './components/ExchangeSummaryTable'
import NotFound from "./components/NotFound";
import ExchangesDonut from "./charts/ExchangesDonut";
import exchangelogo from "./components/logos/exchangelogo.svg";
import { doFetchJson } from "./utils/api";
import { Helmet } from "react-helmet";
import AdReports from "./utils/AdReports";

const ExchangeSummary = (props) => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false)
  const [exchange, setExchange] = useState([])
  const [supported_exchanges, setSupportedExchanges] = useState([])
  const thisExchange = useParams().exchange

  const location = useLocation()

  const getSummary = React.useCallback(() => {
    fetch(process.env.REACT_APP_API_URL + "/v1/ticker/exchanges/" + thisExchange)
      .then(res => doFetchJson(res))
      .then(res => {
        setExchange(res)
        setIsLoaded(true)
      })
      .catch((error) => {
        setError(error)
        setIsLoaded(true)
      })
    Array.from(document.getElementsByClassName('toupdate')).forEach((el) => el.classList.add('updated'));
  }, [thisExchange]);

  const getSupportedExchanges = React.useCallback(() => {
    fetch(process.env.REACT_APP_API_URL + "/v1/exchanges/")
      .then(res => doFetchJson(res))
      .then(res => {
        setSupportedExchanges(res.filter(function (entry) {
          return entry.Name === thisExchange
        }))
        setIsLoaded(true)
      })
      .catch((error) => {
        setError(error)
        setIsLoaded(true)
      })
  }, [thisExchange]);

  useEffect(() => {
    let timer = setInterval(() => (getSummary()), 100000);
    getSummary();
    getSupportedExchanges();
    if (location.pathname.split('/')[2] !== thisExchange) {
      setIsLoaded(false)
      getSummary();
    }
    return () => { clearInterval(timer); }
  }, [getSummary, getSupportedExchanges, thisExchange, location.pathname]);

  if (!isLoaded) {
    return <Container className="text-center p-5"><br /><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else if (!supported_exchanges || error) {
    return (<><NotFound /></>)
  } else {

    const BreadcrumbList = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Criptomoedas",
          "item": "https://mercadocripto.livecoins.com.br/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Corretoras",
          "item": "https://mercadocripto.livecoins.com.br/corretoras",
        }, {
          "@type": "ListItem",
          "position": 3,
          "item": "https://mercadocripto.livecoins.com.br/corretoras" + thisExchange,
          "name": thisExchange
        }]
    };

    return (
      <>
        <Helmet>
          <title>{thisExchange + " - Dados de volume e preço das criptomoedas negociadas em real brasileiro na corretora · MercadoCripto"}</title>
          <meta name="description" content={"Dados de volume e de preço das criptomoedas negociadas na " + thisExchange + " em real brasileiro. O volume detalhado por par negociado em tempo real para todas as  criptomoedas. Acesso a gráficos e dados históricos."} />
          <script type="application/ld+json">{JSON.stringify(BreadcrumbList)}</script>
        </Helmet>
        <div className="mb-5">
          <Container><div className="mx-1">
            <Breadcrumb className="py-2 mb-2">
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/corretoras" }}>
                Corretoras
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{thisExchange}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="d-none d-lg-block pb-1" >
              <AdReports />
            </div>
            <Row>
              <Col md={4}>
                <h1 className="h1 display-5 fw-medium">
                  <svg className="exchangelogo mr-2" role="img" aria-labelledby="title">
                    <title id="title">{thisExchange}</title>
                    <use href={exchangelogo + "#" + (thisExchange.toLowerCase())}></use>
                  </svg>
                  <span className="align-middle mx-2 fw-semibold">{thisExchange}</span>
                </h1>
                <div className="mt-4">
                  <ListGroup horizontal className='my-2'>
                    <ListGroup.Item className="me-auto border-0 h6 text-muted">Vol 24h <small>(pares BRL)</small></ListGroup.Item>
                    <ListGroup.Item className="border-0 h6">{exchange.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</ListGroup.Item>
                  </ListGroup>
                  <ListGroup horizontal className='my-2'>
                    <ListGroup.Item className="me-auto mb-0 border-0 h6 text-muted">No.pares BRL</ListGroup.Item>
                    <ListGroup.Item className="border-0 mb-0 h6">{exchange.length}</ListGroup.Item>
                  </ListGroup>
                  <ListGroup horizontal className='border-0 my-2'>
                    <ListGroup.Item className="me-auto border-0 h6 text-muted">Link</ListGroup.Item>
                    <ListGroup.Item className="border-0 h6"><a href={supported_exchanges.URL !== undefined ? supported_exchanges.URL : "#"} rel="nofollow noopener noreferrer" target="_blank" className="text-muted pl-1">{supported_exchanges.URL !== undefined ? supported_exchanges.URL.substring(8) : ""}</a></ListGroup.Item>
                  </ListGroup>
                </div>
              </Col>
                  {/* <Col md={5}>
                    <VolChart exchange={thisExchange} />
                  </Col> */}
                  <Col className="justify-content-center d-none d-md-block">
                    <ExchangesDonut data={exchange} />
                  </Col>
            </Row>
          </div>
          </Container>
        </div>
        <Container className="overflow-auto p-2 my-5">
          <ExchangeSummaryTable data={exchange} full={exchange.reduce((a, e) => a + e.QuoteVolume, 0)} />
        </Container>
      </>
    );
  }
}

export default ExchangeSummary;